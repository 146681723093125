.nav-bar{
	background-color: rgba(35,31,32,0.4);
}

.main-menu{
	li{
		a{
			padding: 15px 25px 15px 0px;
			color: #FFF;
		}
	}
}

.sub-menu-text{
	margin-top: 15px;
	margin-bottom: 15px;
	color: #2B388F;
	a{
		border-right: 1px solid #2B388F;
		padding-right: 15px;
		padding-left: 15px;
		color: #2B388F;
		&:first-of-type{
			border-left: 1px solid #2B388F;
			margin-left: 15px;
			@media screen and (max-width: 39.9375em) {
				margin-left: 0;
			}
		}
		&:hover{
			text-decoration: underline;
		}
	}
}