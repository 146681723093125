body {
	background: rgb(27,29,66) no-repeat center center fixed !important;
	background: -moz-linear-gradient(45deg, rgba(27,29,66,1) 0%, rgba(29,112,183,1) 100%) no-repeat center center fixed !important;
	background: -webkit-linear-gradient(45deg, rgba(27,29,66,1) 0%,rgba(29,112,183,1) 100%) no-repeat center center fixed !important;
	background: linear-gradient(45deg, rgba(27,29,66,1) 0%,rgba(29,112,183,1) 100%) no-repeat center center fixed !important;
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1b1d42', endColorstr='#1d70b7',GradientType=1 ) no-repeat center center fixed !important;
	-webkit-background-size: cover !important;
	-moz-background-size: cover !important;
	-o-background-size: cover !important;
	background-size: cover !important;
}

.strap-line{
	color: #009EE2;
	font-weight: 300;
}

.parent-c {
  position: relative;
}
.child-c {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}